import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import './assets/layout/layout.scss';
import './assets/layout/flags/flags.css';


import { createApp, reactive } from 'vue';
import router from './router';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import ErrorMessageBc from './components/app/ErrorMessageBc.vue';

import Axios from 'axios';

import CodeHighlight from './AppCodeHighlight';
import ProfileService from "./service/ProfileService";
import store from './store'

import GAuth from 'vue3-google-oauth2';

window.appData = {
    services: {},
    $http: Axios,
    env: {
        rssUrl: 'https://www.freecard.fr/feed/',
        /**
         * @see https://rss2json.com/#rss_url=https%3A%2F%2Fwordpress.org%2Fnews%2Ffeed%2F
         */
        rssApiKey: 'zwwuykn1r05lf0sx0sfg9kwyqilebhle5uowqmmd',
        profileBaseUrl: "https://p.freecard.fr/p/{id}/{key}",
        gauthOption: {
            clientId: '237526428625-2aup49341r828325p6aij5d7955ff237.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/calendar',
            prompt: 'select_account',
            fetch_basic_profile: false,
        },
        notificationRefreshMs: 60000,//dev
        //notificationRefreshMs: 30000,//prod
    },
    tiny: {
        /**
         * @see https://www.tiny.cloud/my-account/profile/
         */
        apiKey: 'o1q1k6ajqz6vlj96uf3qea88mfyd44cxu8q5o4d9odsxjt1v',
    },
    ui: {
        sidebar: true,
        top: true,
        settings: false,
        layout: 'static',// static or overlay
        leftProfile: false,
    },
    credentials: {
        jwt: undefined,
        user: undefined,
        authd: false,
        requested: false,
        logout: () => {
            localStorage.setItem('_jwt_',  undefined);
            localStorage.setItem('user',  undefined);
            document.location.reload();
        }
    },
    menu: [
        {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
        // REAL MENU

        {
            label: 'CRM', icon: 'pi pi-fw pi-briefcase',
            meta: {
                requiresAuth: true,
            },
            items: [
                {label: 'Contacts', icon: 'pi pi-fw pi-user', to: '/crm/contacts'},

                {
                    label: 'Campagnes SMS',
                    icon: 'pi pi-fw pi-phone',
                    to: '/crm/sms-campaigns',
                    disabled: true,
                    badge: "soon"
                },
                {
                    label: 'Campagnes E-Mail',
                    icon: 'pi pi-fw pi-envelope',
                    to: '/crm/mailing',
                    disabled: true,
                    badge: "soon"
                },
                {label: 'Calendrier', icon: 'pi pi-fw pi-calendar',
                    meta: {
                        requiresAuth: true,
                        is_calendar: true,
                    },
                    to: '/crm/calendar'},

            ]
        },
        {
            label: 'Mon Profil', icon: 'pi pi-fw pi-id-card',
            items: [
                {label: 'Editer mon profil', icon: 'pi pi-fw pi-user-edit', to: '/profile/me'},
                {label: 'Gérer mes médias', icon: 'pi pi-fw pi-cloud-upload', to: '/profile/public-files'},
                {label: 'Mes Campagnes', icon: 'pi pi-fw pi-chart-line', to: '/crm/campaigns'},
                {label: 'Générer un lien', icon: 'pi pi-fw pi-link', to: '/profile/qr-code'},
                {label: 'Générer un poster', icon: 'pi pi-fw pi-image', to: '/print/poster'},
            ]
        },
        {
            label: 'Mon Organisation', icon: 'pi pi-fw pi-share-alt',
            meta: {
                requiresAuth: true,
                is_supervisor: true,
            },
            items: [
                {label: 'Mes utilisateurs', icon: 'pi pi-fw pi-users', to: '/supervisor/users'},
            ]
        },

        {label: 'Paramètres du compte', icon: 'pi pi-fw pi-cog', to: '/settings/profile'},
        {
            label: 'Administration', icon: 'pi pi-fw pi-key',
            meta: {
                requiresAuth: true,
                is_admin: true,
            },
            items: [
                {label: 'Utilisateurs', icon: 'pi pi-fw pi-users', to: '/superadmin/users'},
                {label: 'Plan & abonnement', icon: 'pi pi-fw pi-credit-card', to: '/superadmin/plan'},
            ]
        },
        {label: 'Voir mon profil', class: 'menu-yellow', icon: 'pi pi-fw pi-external-link', command: () => {
            try {

                if(window.appData.services.profile !== undefined){
                    window.appData.services.profile.getProfileInfo()
                        .then(data => {
                            let link = document.createElement("a");
                            link.setAttribute('target', '_blank');
                            link.href = window.appData.env.profileBaseUrl.replace('{id}', data.data.id).replace('{key}', data.data.guid.substring(0,10));
                            document.body.appendChild(link);
                            link.click();
                            link.remove();

                        })

                }
            } catch (e) {
                console.error('service does not exists yet !')
            }

        }},
        {label: 'Assistance', icon: 'pi pi-fw pi-question', to: '/documentation'},
        {label: 'Déconnection', icon: 'pi pi-fw pi-power-off', command: () => {
                window.appData.credentials.logout();
            }},
    ]
};

window.appData.$http.defaults.baseURL = 'https://api.freecard.fr';
//window.appData.$http.defaults.headers.common['accept'] = 'application/ld+json';
window.appData.services.profile = new ProfileService();

const token = localStorage.getItem('_jwt_');
const userData = localStorage.getItem('user');

if(userData && userData != 'undefined' && userData != undefined) {
    window.appData.credentials.user = JSON.parse(userData);
}

if(token && token != 'undefined' && token != undefined) {
    window.appData.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    window.appData.services.profile.getProfileInfo()
        .then(data => {
            if(data.error !== undefined && data.error === true){
                document.location.reload();
                return;
            }

        })
        .catch(err => {
            console.warn(err);
            document.location.reload();
        })
}

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);



    if(to.matched.some(record => record.meta.hideMenus)) {
        window.appData.$main.topMenuDisplay(false);
    } else {
        window.appData.$main.topMenuDisplay(true);
    }


    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('_jwt_') == null || window.appData.credentials.user == null || window.appData.credentials.user == undefined) {
            window.appData.$main.topMenuDisplay(false);
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            if(to.matched.some(record => record.meta.is_admin)) {
                if(window.appData.credentials.user !== undefined
                    && window.appData.credentials.user.roles.includes('ROLE_ADMIN')){
                    next()
                }
                else{
                    next({ name: 'dashboard'})
                }
            }else if(to.matched.some(record => record.meta.is_supervisor)) {
                if(window.appData.credentials.user !== undefined
                    && window.appData.credentials.user.roles.includes('ROLE_SUPERVISOR')){
                    next()
                }
                else{
                    next({ name: 'dashboard'})
                }
            }else {
                next()
            }
        }
    } else if(to.matched.some(record => record.meta.guest)) {
        if(window.appData.credentials.jwt === undefined){
            next()
        }
        else{
            next({ name: 'dashboard'})
        }
    }else {
        next()
    }
});

const app = createApp(App).use(store);
window.appData.$app = app;

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });

app.config.devtools = true;



app.use(GAuth, window.appData.env.gauthOption);


app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FullCalendar', FullCalendar);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('ErrorMessageBc',ErrorMessageBc);

app.mount('#app');