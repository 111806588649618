export default {
    methods: {
       generateRandomColor(){
           return "#" + Math.floor(Math.random()*16777215).toString(16);
       },
        randomColorArrayOf(size){
           let arr = [];
            for (let i = 0; i < size; i++) {
                // do something
                arr.push(this.generateRandomColor())
            }

            return arr;
        }
    }
}
