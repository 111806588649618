<template>
	<div class="layout-profile">
		<div>
			<img v-if="userData == undefined || userData.profil_img == ''" src="assets/layout/images/profile.png" alt="" />
			<div v-else class="img_profil w-100 p-mb-3">
				<div style="margin:auto;width: 100%;max-width: 64px;" class="profil_img_container">
					<img style="margin: 0;width: 100%;" :src="userData.profil_img" class="square-border" alt="" />
				</div>
			</div>
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span v-if="userData !== undefined" class="username">{{ userData.firstname }} {{ userData.lastname }}</span>
			<span v-else class="username">John Doe</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button @click="viewProfileSettings" class="p-link"><i class="pi pi-fw pi-user"></i><span>Paramètres du compte</span></button></li>
                <!--<li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li>-->
                <li><button @click="logout" class="p-link"><i class="pi pi-fw pi-power-off"></i><span>Déconnexion</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import ProfileService from "./service/ProfileService";

	export default {
		data() {
			return {
				expanded: false,
				lockedProps: undefined,
			}
		},
		created(){
			this.profileService = new ProfileService();
		},
		mounted(){

		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			logout(){
				window.appData.credentials.logout();
			},
			viewProfileSettings() {
				this.$router.push({ name: 'settings-profile'})
			},
		},
		computed: {
			...mapState({
				userData: 'user',
			})
		}
	}
</script>

<style scoped>

</style>