export default class CrmService {

    async updateBusinessHours(BhApiId, payload) {
        return await window.appData.$http.put(BhApiId, payload)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async deleteBusinessHours(BhApiId) {
        return await window.appData.$http.delete(BhApiId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async getCalendarEvent(BhApiId) {
        return await window.appData.$http.get(BhApiId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async updateCalendarEvent($ceApiId, payload) {
        return await window.appData.$http.put($ceApiId, payload)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async createBusinessHours() {
        return await window.appData.$http.post('/apx/gcal/calendar/business_hours')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }


    async listBusinessHours() {
        return await window.appData.$http.get('/apx/gcal/calendar/business_hours')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async listCalendarEventFromTo(from_, to, statusFilters = undefined) {
        let payload = {
            'from': from_,
            'to': to,
        };

        if(statusFilters !== undefined){
            payload.statusFilters = statusFilters;
        }

        return await window.appData.$http.get('/apx/gcal/calendar/calendar_event',
            {
                params: payload
            })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async listCalendarEvent(contactGuid = undefined, googleId = undefined, filters = undefined, row = undefined, page = undefined) {
        let payload = {};

        if(contactGuid !== undefined){
            payload.contactGuid = contactGuid;
        } else if(googleId !== undefined){
            payload.googleId = googleId;
        }

        if(row !== undefined){
            payload.limit= row;
        }
        if(page !== undefined){
            payload.page= page;
        }

        if(filters !== undefined){
            payload.statusFilters= filters;
        }


        return await window.appData.$http.get('/apx/gcal/calendar/calendar_event',
            {
                params: payload
            })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }


    async listCalendars() {
        return await window.appData.$http.get('/apx/gcal/calendar')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async listEvents(startDate, endDate) {
        return await window.appData.$http.get('/apx/gcal/calendar/event', {
            params: {
                startDate: startDate,
                endDate: endDate,
            }
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

}
