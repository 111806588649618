export default class CrmService {

    async getContact(apiImageIp) {
        return await window.appData.$http.get(apiImageIp)
            .then(res => {
                return {
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async deleteContact(linkApiId) {
        return await window.appData.$http.delete(linkApiId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async updateContact(contactApiId, payload) {
        return await window.appData.$http.put(contactApiId, payload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async downloadOneContact(guid){
        return await window.appData.$http.get('/apx/crm/contact/' + guid, {
            params: {
                dl: 1,
            },
            responseType: 'blob',
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let date = new Date();

                link.setAttribute('download', 'export_' + date.getDay() + '_' + date.getMonth()
                    + '_' + date.getFullYear() + '__' + date.getHours() + '_' + date.getMinutes() + '.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async downloadContact(order = undefined, orderDirection = true,
                    filterStatus = undefined,
                    queryString = undefined){


        const payload = {
            rows: 10000000,
            page: 1,
            order: order || null,
            direction: Number(orderDirection),
            filterStatus: filterStatus || null,
            queryString: queryString || null,
            dl: 1,
        };

        return await window.appData.$http.get('/apx/crm/contact', {
            params: payload,
            responseType: 'blob',
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let date = new Date();

                link.setAttribute('download', 'export_' + date.getDay() + '_' + date.getMonth()
                    + '_' + date.getFullYear() + '__' + date.getHours() + '_' + date.getMinutes() + '.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
    /**
     *
     * @param rows
     * @param page
     * @param order
     * @param orderDirection
     * @param filterStatus
     * @param queryString
     * @returns {Promise<{data: T, error: boolean, status: number} | {data: *, error: boolean, message: *}>}
     */
    async getContacts(rows = 20, page = 1,
                      order = undefined, orderDirection = true,
                      filterStatus = undefined,
                      queryString = undefined) {

        const payload = {
            rows: rows,
            page: page,
            order: order || null,
            direction: Number(orderDirection),
            filterStatus: filterStatus || null,
            queryString: queryString || null,
        };

        return await window.appData.$http.get('/apx/crm/contact', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
}
