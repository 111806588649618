let baseValue = 0;

export default class ProfileService {

    async deleteAccount(payload) {
        return await window.appData.$http.delete('/apx/user/' + payload.id )
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async updateAccount(payload) {
        return await window.appData.$http.put('/apx/user', payload)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async createAccount(payload) {
        return await window.appData.$http.post('/apx/user', payload)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async updateAdminUser(apiId, payload) {
        return await window.appData.$http.put(apiId, payload)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getSubUsers( rows = 20, page = 1,  orderDirection = true) {

        const payload = {};

        if(rows !== undefined){
            payload.rows = rows;
        }

        if(page !== undefined){
            payload.page = page;
        }


        if(orderDirection !== undefined){
            payload.direction = orderDirection;
        }

        return await window.appData.$http.get('/apx/user', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async admin_getUsers( rows = 20, page = 1,  orderDirection = true, query = undefined) {

        const payload = {};

        if(rows !== undefined){
            payload.rows = rows;
        }

        if(page !== undefined){
            payload.page = page;
        }

        if(query !== undefined){
            payload.query = query;
        }


        if(orderDirection !== undefined){
            payload.direction = orderDirection;
        }

        return await window.appData.$http.get('/apx/superadmin/user', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getJWT(username, password) {
        const self = this;
        return await window.appData.$http.post('/login_check', {
            username: username,
            password: password,
        })
            .then(async res => {
                let d = res.data;
                window.appData.credentials.jwt = d.token;
                localStorage.setItem('_jwt_',  window.appData.credentials.jwt);
                window.appData.$http.defaults.headers.common['Authorization'] = 'Bearer ' + d.token;

                let userData  = await self.getProfileInfo()
                    .catch(err => {
                        throw new Error(err.message);
                    })
                ;
                if(userData.error === true){
                    throw new Error(userData.message);
                }
                return {
                    status: res.status,
                    error: false,
                    data: d,
                };
            })
            .catch(err => {
                console.warn(err);
                localStorage.setItem('_jwt_',  undefined);
                localStorage.setItem('user',  undefined);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async reinitGoogle() {
        return await window.appData.$http.delete('/apx/users/me/google_auth', {})
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async updateProfileInfo(payload) {
        const self= this;
        return await window.appData.$http.put('/apx/users/me', payload)
            .then(res => {
                self.getProfileInfo();
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async updateNotification(apiId, payload) {
        return await window.appData.$http.put(apiId, payload)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getNotifications(rows = 20, page = 1) {
        return await window.appData.$http.get('/apx/users/notifications', {
            params: {
                rows: rows,
                page: page,
            }
        })
            .then(res => {
                let d = res.data;
                localStorage.setItem('count_notif',  d['@meta'].unread);
                if(baseValue !== Number(d['@meta'].unread) && Number(d['@meta'].unread) > baseValue){
                    const event = new Event('app_notification', {
                        data: res.data,
                    });
                    window.dispatchEvent(event)
                }

                baseValue = Number(d['@meta'].unread);
                if(window.appData.$main !== undefined && window.appData.$main.$store !== undefined){
                    window.appData.$main.$store.commit('UPDATE_COUNT_NOTIFS', d['@meta'].unread);
                }

                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getProfileInfo() {
        return await window.appData.$http.get('/apx/users/me')
            .then(res => {
                let d = res.data;
                window.appData.credentials.user = d.data;
                localStorage.setItem('user',  JSON.stringify(window.appData.credentials.user));

                if(window.appData.$main !== undefined && window.appData.$main.$store !== undefined){
                    window.appData.$main.$store.commit('UPDATE_USER', d.data);
                }
                return d;
            })
            .catch(err => {
                console.error(err);
                localStorage.setItem('_jwt_',  undefined);
                localStorage.setItem('user',  undefined);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getLockedProps() {
        return await window.appData.$http.get('/apx/props/locked')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getHelp() {
        return await window.appData.$http.get('/apx/help')
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async admin_getLockedProps(userId) {
        return await window.appData.$http.get('/apx/superadmin/props/locked/user/' + userId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }


    async getInfo(infoApiId) {
        return await window.appData.$http.get(infoApiId)
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async updateInfo(infoApiId, payload) {
        return await window.appData.$http.put(infoApiId, payload)
            .then(res => {
                console.log(res);
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getGoogleTokens(authCode, redirectTo) {
        return await window.appData.$http.post('/apx/users/me/google_auth', {
            auth_code: authCode,
            redirect_to: redirectTo,
        })
            .then(res => {
                console.log(res);
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
}
