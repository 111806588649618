import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
    },

    // actual application router
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
    },
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            hideMenus: true
        },
        component: () => import('./pages/LoginPage.vue'),
    },
    {
        path: '/settings/profile',
        name: 'settings-profile',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/SelfProfilPage.vue'),
    },
    {
        path: '/print/poster',
        name: 'print-poster',
        meta: {
            requiresAuth: true,
        },
        component: () => import('./pages/printPages/PrintPosterPage.vue'),
    },
    {
        path: '/profile/notifications',
        name: 'notifications',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/NotificationPage.vue'),
    },
    {
        path: '/profile/me',
        name: 'profile',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/InfoPage.vue'),
    },
    {
        path: '/profile/qr-code',
        name: 'qr-code',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/QrCodePage.vue'),
    },
    {
        path: '/profile/public-files',
        name: 'public-files',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/PublicFilesPage.vue'),
    },
    {
        path: '/crm/contacts',
        name: 'crm-contacts',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/CrmContactPage.vue'),
    },
    {
        path: '/crm/contacts/:guid',
        name: 'crm-contacts-profile',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/CrmContactProfilePage.vue'),
    },
    {
        path: '/crm/calendar',
        name: 'crm-calendar',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/CalendarViewPage.vue'),
    },
    {
        path: '/crm/campaigns',
        name: 'crm-campaigns',
        meta: {
            requiresAuth: true
        },
        component: () => import('./pages/CampaignListPage.vue'),
    },
    {
        path: '/supervisor/users',
        name: 'supervisor-users',
        meta: {
            requiresAuth: true,
            is_supervisor: true,
        },
        component: () => import('./pages/ListUsersPage.vue'),
    },
    {
        path: '/superadmin/users',
        name: 'superadmin-users',
        meta: {
            requiresAuth: true,
            is_admin: true,
        },
        component: () => import('./pages/AdminListUsersPage.vue'),
    },
    {
        path: '/superadmin/plan',
        name: 'superadmin-plan',
        meta: {
            requiresAuth: true,
            is_admin: true,
        },
        component: () => import('./pages/AdminListPlanPage.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
