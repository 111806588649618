 <template>
<div class="p-grid p-fluid dashboard">

	<div class="p-col-12 p-mb-4">
		<div class="p-fluid">
			<h1>Bonjour {{ userData.firstname }}</h1>
			<p class="text-muted">Bienvenu sur votre espace FreeCard !</p>
		</div>
	</div>

	<div v-if="userData.cardStatus === 2" class="p-col-12">
		<div class="card w-card">
			<h5>En attente d'information</h5>
			<p class="">Nous attendons que vous ayez renseigné toutes vos informations de contact pour lancer
			le processus de production de votre carte FreeCard.<br>
			N'attendez plus, <b>finissez dès maintenant de remplir votre profil</b> !</p>
		</div>
	</div>

	<div v-if="userData.cardStatus === 4" class="p-col-12">
		<div class="card w-card">
			<h5>Carte en livraison</h5>
			<p class="">Vous l'attendiez, elle est en chemin pour arriver enfin chez vous !<br>
				Vous pouvez suivre le colis en cliquant sur le lien suivant :
			</p>
			<p class="p-text-center">
				<a target="_blank" rel="noreferrer noopener" :href="userData.urlSuiviColis">{{ userData.urlSuiviColis }}</a>
			</p>
		</div>
	</div>

	<div v-if="userData.cardStatus === 3" class="p-col-12">
		<div class="card w-card">
			<h5>En attente de validation</h5>
			<p class="">Notre équipe est en train de valider les informations que vous nous avez fourni.<br>
				Restez à l'écoute, votre carte sera bientôst en chemin !</p>
		</div>
	</div>

	<div class="p-col-12 p-lg-4">
		<div class="card summary ">
			<span class="title">Visiteurs</span>
			<span class="detail text-muted">nombre de visites sur 30 jours</span>
			<span class="count visitors square-border">{{ totalVisitorCurrentTs }}</span>
		</div>
	</div>
	<div class="p-col-12 p-lg-4">
		<div class="card summary b-card">
			<span class="title">Contacts</span>
			<span class="detail">nombre de contacts sur 30 jours</span>
			<span class="count purchases square-border">{{ totalContactCurrentTs }}</span>
		</div>
	</div>
	<div class="p-col-12 p-lg-4">
		<div class="card summary">
			<span class="title">Rendez-vous</span>
			<span class="detail text-muted">nombre de rdv sur 30 jours</span>
			<span class="count revenue square-border">{{ totalMeetingCurrentTs }}</span>
		</div>
	</div>

	<div class="p-col-12 p-lg-4 contacts">
		<div class="card">
			<h5>Visiteurs <br><small class="text-muted">flux de visiteurs sur 30 jours</small></h5>


			<div class="p-fluid" v-if="visitorsDataset !== undefined && totalVisitorCurrentTs > 0">
				<Chart v-if="visitorsDataset !== undefined" type="bar" :data="visitorsDataset"  :options="basicOptions"  />
			</div>
			<div class="p-fluid" v-else-if="visitorsDataset !== undefined && totalVisitorCurrentTs <= 0">
				<ErrorMessageBc type="sea_ship" message="Aucune Visite"></ErrorMessageBc>
			</div>
			<div class="p-fluid" v-else>
				<Skeleton width="100%" height="12rem"/>
			</div>
		</div>
	</div>



	<div class="p-col-12 p-lg-4 contacts">
		<div class="card">
			<h5>Derniers Contacts <br><small class="text-muted">nouveaux contacts</small></h5>
			<ul v-if="lastContacts !== undefined && lastContacts.length > 0">
				<li v-for="cnt in lastContacts" :key="cnt.id">
					<button @click.prevent="viewContact(cnt)" class="p-link">
						<img v-if="cnt.gender === 3" src="assets/layout/images/avatar_4.png" width="35" alt="avatar4"/>
						<img v-if="cnt.gender === 2" src="assets/layout/images/avatar_3.png" width="35" alt="avatar3"/>
						<img v-if="cnt.gender === 1" src="assets/layout/images/avatar_1.png" width="35" alt="avatar1"/>
						<img v-if="cnt.gender === 0" src="assets/layout/images/avatar_2.png" width="35" alt="avatar2"/>
						<span class="name">{{ cnt.firstname }} {{ cnt.lastname }}</span>
						<span class="email">{{ cnt.email }}</span>
					</button>
				</li>
			</ul>
			<div class="p-fluid" v-else-if="lastContacts !== undefined && lastContacts.length <= 0">
				<ErrorMessageBc type="no_stats" message="Pas de contacts" detail="Commencez à utiliser Freecard dès maintenant !"></ErrorMessageBc>
			</div>
			<ul v-else>
				<Skeleton width="100%" height="12rem"/>
			</ul>
		</div>
	</div>

	<div class="p-col-12 p-lg-4 contacts">
		<div class="card">
			<h5>Rendez-vous <br><small class="text-muted">à valider ou en attente</small></h5>
			<ul v-if="calendarEvents !== undefined && calendarEvents.length > 0">
				<li v-for="cevt in calendarEvents" :key="cevt.id">
					<button v-bind:class="{'event-muted': isBefore(cevt.start)}"
							@click.prevent="viewContactApi(cevt.attendee)"
							class="p-link" >
						<!-- todo new image for different status of rdv -->
						<span class="p-tag p-tag-rounded tag-status"
							  v-bind:class="{'p-tag-success': cevt.status === 2,
												 'p-tag-info': cevt.status === 0 ||  cevt.status === 1,
												  'p-tag-danger': cevt.status === 3 ||  cevt.status === 4 ||  cevt.status === 5,}">
											{{ statusToStr(cevt.status,true) }}
										</span>
						<img src="assets/layout/images/avatar_2.png" style="opacity: 0" width="35" alt="avatar1"/>
						<span class="name smaller">{{ cevt.title }}</span>
						<span class="email">{{ isoRangeToHumanRange(cevt.start, cevt.end) }}</span>
					</button>
				</li>
			</ul>
			<div class="p-fluid" v-else-if="calendarEvents !== undefined && calendarEvents.length <= 0">
				<ErrorMessageBc type="calendar" message="Aucun Rendez-vous" detail="Commencez à utiliser Freecard dès maintenant !"></ErrorMessageBc>
			</div>
			<ul v-else>
				<Skeleton width="100%" height="12rem"/>
			</ul>


		</div>
	</div>



	<div class="p-col-12 p-lg-8">
		<div class="card contacts">
			<h5>Contacts <br><small class="text-muted">au cours des 30 derniers jours</small></h5>
			<Chart v-if="dataContactsCount !== undefined" type="bar" :data="dataContactsCount"  :options="basicOptions"  />
			<div v-else class="p-fluid" >
				<Skeleton  width="100%" height="18rem" class="p-mb-1" />
			</div>
		</div>
	</div>

	<div class="p-col-12 p-md-4">
		<div class="card contacts">
			<h5>Campagnes <br><small class="text-muted">au cours des 30 derniers jours</small></h5>
			<Chart v-if="dataContactsEvents !== undefined" type="pie" :data="dataContactsEvents" />

			<div v-else class="p-fluid" >
				<Skeleton  width="100%" height="2rem" class="p-mb-1" />
				<Skeleton shape="circle" size="12rem" style="margin:auto"/>
			</div>
		</div>
	</div>





	<div class="p-col-12 contacts">
		<div class="card">
			<h5>Actualités <br><small class="text-muted">les dernières news Freecard</small></h5>
			<ScrollPanel class="custom" v-if="rssFeed !== undefined" style="width: 100%; height: 300px">
				<div v-for="article in rssFeed" :key="article.guid" class="p-fluid p-m-1 p-mt-3 article-container">
					<div class="article-title">
						{{ article.title }}
					</div>
					<div v-html="article.content" class="article-content"></div>
				</div>
			</ScrollPanel>
			<div v-else class="p-fluid">
				<Skeleton width="100%" height="12rem"/>
			</div>
		</div>
	</div>




</div>
</template>

<script>
	import { mapState } from 'vuex';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import StatsService from "../service/StatsService";
import CrmService from "../service/CrmService";
import RssService from "../service/RssService";
import ScrollPanel from 'primevue/scrollpanel';
import colorMixin from "../mixins/colorMixin";
import CalendarService from "../service/CalendarService";
import calendarMixin from "../mixins/calendarMixin";
	import ProfileService from "../service/ProfileService";

export default {
	data() {
		return {
			tasksCheckbox: [],
			dropdownCities: [
				{name: 'New York', code: 'NY'},
				{name: 'Rome', code: 'RM'},
				{name: 'London', code: 'LDN'},
				{name: 'Istanbul', code: 'IST'},
				{name: 'Paris', code: 'PRS'}
			],
			dropdownCity: null,
			options: {
				plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
				defaultDate: '2019-01-01',
				header: {
					left: 'prev,next',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay'
				},
				editable: true
			},
			events: null,
			products: null,
			selectedProducts: null,
			lineData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'First Dataset',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860'
					}
				]
			},
			/**
			 * @see https://www.chartjs.org/docs/latest/configuration/decimation.html
			 */
			basicOptions: {
				legend: {
					display: false
				},
			},
			dataContactsCount: undefined,
			defaultTimeSpan: 'last_month',
			totalContactCurrentTs: 0,
			totalMeetingCurrentTs: 0,
			totalVisitorCurrentTs: 0,
			visitorsDataset: undefined,
			lastContacts: undefined,
			rssFeed: undefined,
			dataContactsEvents: undefined,
			calendarEvents: undefined,
		}
	},
	created() {
		this.statsService = new StatsService();
		this.crmService = new CrmService();
		this.rssService = new RssService();
		this.calendarService = new CalendarService();
		this.profileService = new ProfileService();
	},
	mounted() {

        this.loadData();
        this.loadRssData();

        this.profileService.getProfileInfo();
	},
	methods: {
		viewContact(contact) {
			this.$router.push({ name: 'crm-contacts-profile', params: { guid: contact.guid } })
		},
		viewContactApi(contactApiId) {
			contactApiId = contactApiId.replace('/api/contacts/','');
			this.$router.push({ name: 'crm-contacts-profile', params: { guid: contactApiId } })
		},
		loadRssData(){
			this.rssService.getRssFeed()
					.then(resp => {
						try {
							this.rssFeed = resp.data.items;
						} catch (e) {
							console.error(e);
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
			;
		},
		loadData(){

			this.calendarService.listCalendarEvent(undefined, undefined, [0,1], 5)
					.then(res => {
						this.calendarEvents = res.data.data || [];
					})
					.finally(() => {
						//vm.loading = false;
					})
			;


			this.statsService.getVisitors(
					this.defaultTimeSpan
			)
					.then(resp => {
						try {
							this.totalVisitorCurrentTs = resp.data.total;
							this.visitorsDataset = {
								labels: resp.data.label,
								datasets: [
									{
										label: 'Visiteurs journalier',
										backgroundColor: '#42A5F5',
										data: resp.data.dataset,
									}
								]
							}
						} catch (e) {
							console.error(e);
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					});

			this.statsService.countCalendarEvent(
					this.defaultTimeSpan,
					undefined,
					undefined,
					[2]
			)
					.then(resp => {
						try {
							this.totalMeetingCurrentTs = resp.data.data.total
						} catch (e) {
							console.error(e);
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})

			this.statsService.getContactStats(
					null,
					this.defaultTimeSpan,
			)
					.then(resp => {
						try {
							this.totalContactCurrentTs = resp.data.data.total
						} catch (e) {
							console.error(e);
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
			;

			this.statsService.getContactEvent(
					this.defaultTimeSpan,
			)
					.then(resp => {
						this.dataContactsEvents = {
							labels: resp.data.label,
							datasets: [
								{
									backgroundColor: this.randomColorArrayOf(resp.data.label.length),
									data: resp.data.dataset,
								}
							]
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
			;

			this.crmService.getContacts(5,1)
					.then(resp => {
						this.lastContacts = resp.data.data;
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					});

			this.statsService.getContactStats(
					'daily',
					this.defaultTimeSpan,
			)
					.then(resp => {
						this.dataContactsCount = {
							labels: resp.data.label,
							datasets: [
								{
									label: 'Nombre de contacts',
									backgroundColor: '#42A5F5',
									data: resp.data.dataset,
								}
							]
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
			;
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		}
	},
	computed: {
		...mapState({
			userData: 'user',
		}),
	},
	mixins:[colorMixin, calendarMixin],
	components: {
		ScrollPanel,
	}
}
</script>

<style lang="scss">
	.event-muted {
		opacity: .5;
	}
	.p-link {
		position: relative;
		.tag-status {
			position: absolute;
			left:10px;
			top:15px;
		}
	}


	.article-container {
		position:relative;
	.article-title {
		font-size:1.05rem;
		font-weight: bold;
	}
	.article-content {
		font-size:.8rem;
		overflow-x: hidden;
		position:relative;
		width:100%;
	img {
		max-width: 80%;
		margin: auto;
	}
	}
	}

	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}
</style>
