import moment from "moment";

export default {
    methods: {
        dateToISO(d){
            if(d === null ||d === undefined){
                return undefined;
            }
            function pad(n){return n<10 ? '0'+n : n}
            return d.getUTCFullYear()+'-'
                + pad(d.getUTCMonth()+1)+'-'
                + pad(d.getUTCDate())+'T'
                + pad(d.getUTCHours())+':'
                + pad(d.getUTCMinutes())+':'
                + pad(d.getUTCSeconds())+'Z'
        },
        tmsToDate(tmxUNIX){
            let date = moment.unix(tmxUNIX);


            const options1 = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            const dateTimeFormat = new Intl.DateTimeFormat('fr-FR', options1);

            return dateTimeFormat.format(date.toDate());
        },
        isoStrToDate(isoStr){
            let date = moment(isoStr);

            const options1 = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            const dateTimeFormat = new Intl.DateTimeFormat('fr-FR', options1);

            return dateTimeFormat.format(date.toDate());
        },
        isBefore(isoStr) {
            let date = moment(isoStr);
            return date.isBefore(moment())
        },
        isoRangeToHumanRange(isoFrom, isoTo){
            let fd = moment(isoFrom);
            let td = moment(isoTo);

            const options1 = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            const dateTimeFormat = new Intl.DateTimeFormat('fr-FR', options1);

            /**
             * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
             */
            return dateTimeFormat.formatRange(fd.toDate(), td.toDate());
        },
        tmsRangeToHumanRange(tmsFrom, tmsTo){
            let fd = moment.unix(tmsFrom);
            let td = moment.unix(tmsTo);

            const options1 = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };
            const dateTimeFormat = new Intl.DateTimeFormat('fr-FR', options1);

            /**
             * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
             */
            return dateTimeFormat.formatRange(fd.toDate(), td.toDate());
        },
        canEditStatus(status){
            switch (status) {
                case 0:
                    return true;
                case 1:
                    return true;
                case 2:
                    return true;
                case 3:
                    return false;
                case 4:
                    return false;
                case 5:
                    return false;
                default:
                    return false;
            }
        },
        statusToStr(status, simple = false){
            switch (status) {
                case 0:
                    if(simple){
                        return 'A valider';
                    }
                    return 'En attente de votre acceptation';
                case 1:
                    if(simple){
                        return 'En attente';
                    }
                    return 'En attente de réponse';
                case 2:
                    if(simple){
                        return 'Accepté';
                    }
                    return 'Accepté';
                case 3:
                    if(simple){
                        return 'Annulé';
                    }
                    return 'Vous avez annulé';
                case 4:
                    if(simple){
                        return 'Refusé';
                    }
                    return 'Refusé par le prospect';
                case 5:
                    if(simple){
                        return 'Indisponible';
                    }
                    return 'Vous avez refusé';
                default:
                    return 'Annulé';
            }
        },
    }
}
