import { createStore } from 'vuex'

const userData = localStorage.getItem('user');
let uData = undefined;
if(userData && userData != 'undefined' && userData != undefined) {
  uData = JSON.parse(userData);
}

const notifData = localStorage.getItem('count_notif');
let uNotifs = 0;
if(notifData && notifData != 'undefined' && notifData != undefined) {
  uNotifs = Number(notifData);
}

export default createStore({
  state: {
    user: uData,
    countNotifs: uNotifs,
  },
  mutations: {
    UPDATE_USER(state, payload){
      state.user = payload;
    },
    UPDATE_COUNT_NOTIFS(state, payload){
      state.countNotifs = payload;
    }
  },
  actions: {
    updateUserProfile(context, user) {
      context.commit('UPDATE_USER', user)
    },
    updateCountNotifs(context, countNotifs) {
      context.commit('UPDATE_COUNT_NOTIFS', countNotifs)
    },
  },
  modules: {
  }
})
