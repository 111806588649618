import moment from "moment";

export default class StatsService {
    async countCalendarEvent(span = 'last_7', contactGuid = undefined, googleId = undefined, filters = undefined) {
        let payload = {};

        let fromDate = undefined;
        let toDate = undefined;
        switch (span) {
            case 'last_7':
                fromDate = moment().subtract(7,'d');
                toDate = moment();
                payload.from = fromDate.toISOString();
                //payload.to = toDate.toISOString();
                break;
            case 'last_15':
                fromDate = moment().subtract(15,'d');
                toDate = moment();
                payload.from = fromDate.toISOString();
                //payload.to = toDate.toISOString();
                break;
            case 'last_360':
                fromDate = moment().subtract(1,'y');
                toDate = moment();
                payload.from = fromDate.toISOString();
                //payload.to = toDate.toISOString();
                break;
            case 'last_month':
                fromDate = moment().subtract(1,'M');
                toDate = moment();
                payload.from = fromDate.toISOString();
                //payload.to = toDate.toISOString();
                break;
        }

        console.log(toDate);

        if(contactGuid !== undefined){
            payload.contactGuid = contactGuid;
        } else if(googleId !== undefined){
            payload.googleId = googleId;
        }

        if(filters !== undefined){
            payload.statusFilters= filters;
        }


        return await window.appData.$http.get('/apx/stats/calendar_event_count',
            {
                params: payload
            })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: undefined,
                    message: err.message
                };
            })
            ;
    }

    async getVisitors(span) {
        let payload = {};

        let fromDate = undefined;

        switch (span) {
            case 'last_7':
                fromDate = moment().subtract(7,'d');
                payload.from = fromDate.toISOString();
                break;
            case 'last_15':
                fromDate = moment().subtract(15,'d');
                payload.from = fromDate.toISOString();
                break;
            case 'last_360':
                fromDate = moment().subtract(1,'y');
                payload.from = fromDate.toISOString();
                break;
            case 'last_month':
                fromDate = moment().subtract(1,'M');
                payload.from = fromDate.toISOString();
                break;
        }

        return await window.appData.$http.get('/apx/stats/visitors', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getContactEvent(span, order = undefined, orderDirection = true) {

        const payload = {
            span: span,
            order: order || null,
            direction: Number(orderDirection)
        };

        return await window.appData.$http.get('/apx/stats/contact_event_count', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getContactStats(plage, span,
                      order = undefined, orderDirection = true) {

        const payload = {
            plage: plage,
            span: span,
            order: order || null,
            direction: Number(orderDirection)
        };

        return await window.appData.$http.get('/apx/stats/contact_count', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
}
