<template>
	<div class="layout-topbar" v-bind:class="{'scroll-top': toTop}">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>

		<div class="topbar-content p-d-flex p-jc-between p-ai-center">
			<!-- todo centrer le logo sur la barre top
		<div style="height: 50px; display: inline-block" class="layout-topbar-center">
			<router-link to="/">
				<img alt="Logo" style="max-width: 64px" :src="logo" />
			</router-link>
		</div>-->

			<div class="layout-topbar-left">

			</div>

			<div class="layout-topbar-center">

			</div>

			<div class="layout-topbar-icons">
				<!--<span class="layout-topbar-search">
                    <InputText type="text" placeholder="Search" />
                    <span class="layout-topbar-search-icon pi pi-search"></span>
                </span>
                <button class="p-link">
                    <span class="layout-topbar-item-text">Events</span>
                    <span class="layout-topbar-icon pi pi-calendar"></span>
                    <span class="layout-topbar-badge">5</span>
                </button>-->
				<button @click="viewNotifs" class="p-link p-link-w p-mr-4">
					<i v-if="countNotifs > 0" class="layout-topbar-icon badged-icon pi pi-bell"
					   v-badge.danger="countNotifs"></i>
					<i v-else class="layout-topbar-icon badged-icon pi pi-bell"></i>
				</button>

				<a @click.prevent="goDashboard" href="/"
				   alt="Aller au dashboard"
				   v-if="userData !== undefined" class="p-el profile p-mr-4">
					<span class="profile-name p-mr-2">{{ computedName }}</span>
					<div class="profil_img_container">
						<img :src="userData.profil_img" alt="" />
					</div>
				</a>


				<!--
                <button class="p-link">
                    <span class="layout-topbar-item-text">User</span>
                    <span class="layout-topbar-icon pi pi-user"></span>
                </button>-->
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import ProfileService from "./service/ProfileService";

export default {
	data(){
		return {
		}
	},
	props: {
		toTop: {
			type: Boolean,
			default: true,
		}
	},
	created(){
		this.profileService = new ProfileService();
	},
	mounted(){
		this.checkNotification();
	},

    methods: {
		viewNotifs() {
			this.$router.push({ name: 'notifications' })
		},
		timeoutNotification(){
			setTimeout(() => {
				this.checkNotification();
			}, window.appData.env.notificationRefreshMs);
		},
		checkNotification(){
			if(this.userData === undefined){
				return;
			}

			this.profileService.getNotifications(5,1)
					.then(data => {
						if(data.error === true){
							throw new Error(data.message);
						}
					})
					.catch(err => {
						console.warn('err',err);
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.timeoutNotification();
					})
			;
		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		goDashboard() {
			this.$router.push({ name: 'dashboard' })
		},
    },

	computed:{
		...mapState({
			userData: 'user',
			countNotifs: 'countNotifs',
		}),
		computedName(){
			if(this.userData === undefined){
				return '';
			} else {
				return this.userData.firstname + ' ' + this.userData.lastname
			}
		},
		logo() {
			//return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";
			return "assets/images/icons/logo-freecard.svg";
		}
	}
}
</script>