<template>
	<div :class="containerClass" @click="onWrapperClick">

        <transition name="layout-submenu-wrapper">
            <AppTopBar :toTop="toTop" v-show="userData !== undefined && showTopMenu" @menu-toggle="onMenuToggle" />
        </transition>

        <Toast position="bottom-right" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="userData !== undefined && isSidebarVisible()">
                <div class="layout-logo" v-bind:class="{'to-top': toTop}">
                    <!-- todo add lettring -->
                    <router-link to="/">
                        <img src="/assets/images/icons/logo-freecard-text-black.svg"
                             style="width: 67%; max-width: 250px"
                             alt="">
                    </router-link>
                </div>

                <AppProfile v-if="leftProfile" />

                <div class="p-fluid p-mb-5" v-if="!leftProfile" ></div>

                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

		<div class="layout-main" v-bind:class="{'to-top': toTop, 'full-page-mode': userData === undefined}">
			<router-view />
		</div>

        <!--
		<AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode" @layout-change="onLayoutChange" @layout-color-change="onLayoutColorChange"/>
        -->
		<AppFooter v-bind:class="{'full-page-mode': userData === undefined}" v-show="true" />
	</div>
</template>

<script>
    import { mapState } from 'vuex';

import AppTopBar from './AppTopbar.vue';
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
//import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';

const __locale = {
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
    monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Avr", "Mai", "Jui","Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
    today: 'Aujourd\'hui',
    firstDayOfWeek: 1,
};

export default {
    data() {
        return {
            layoutMode: window.appData.ui.layout || 'overlay',
            layoumtColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu : window.appData.menu,
            showTopMenu: true,
            leftProfile: window.appData.ui.leftProfile || false,
            toTop: true,
        }
    },
    created(){
        for (const props in __locale) {
            this.$primevue.config.locale[props] = __locale[props];
        }


        window.addEventListener("scroll", () => {
            this.checkToTop();
        });

        this.checkToTop();
    },
    mounted(){
        window.appData.$main = this;
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        checkToTop(){
            if(window.scrollY==0){
                this.toTop = true;
            } else {
                this.toTop = false;
            }
        },
        topMenuDisplay(show) {
            this.showTopMenu = show;
            this.staticMenuInactive = false;
            this.overlayMenuActive = false;
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if(window.appData.ui.sidebar === false){
                return false;
            }

            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        ...mapState({
            userData: 'user',
        }),
        containerClass() {
            return ['layout-wrapper', {
                'to-top': this.toTop,
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },

    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
//        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
.text-muted {
    opacity: .6;
}
</style>