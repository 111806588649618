import Axios from 'axios';

export default class StatsService {

    async getRssFeed() {
        const axs = Axios.create();
        delete axs.defaults.headers.common['Authorization'];
        const payload = {
            rss_url: window.appData.env.rssUrl,
            api_key: window.appData.env.rssApiKey,
        };

        return await axs.get('https://api.rss2json.com/v1/api.json', {
            params: payload
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
}
