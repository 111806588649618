<template>
	<div class="p-fluid b-error">
		<div class="b-error-content">
			<div class="b-error-text p-text-center">
				<h5>{{ message }}</h5>
				<small>{{ detail }}</small>
			</div>
		</div>
		<div class="b-error-img">
			<img :style="'max-width:' + maxWidth + 'px;'" :src="imageUrl" alt="">
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			data: undefined,
		}
	},
	props: {
		message: {
			type: String,
			default: "Erreur"
		},
		detail: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "default"
		},
		maxWidth: {
			type: Number,
			default: 200,
		}
	},
	created(){

	},
	mounted(){

	},
	methods: {

	},
	computed: {
		imageUrl(){
			let baseUrl = '/assets/layout/images/illustrations/';
			switch(this.type){
				case 'sea_ship':
					return baseUrl + 'container_ship_cuate.svg';
				case 'message':
					return baseUrl + 'message_cuate.svg';
				case 'calendar':
					return baseUrl + 'calendar_cuate.svg';
				case 'no_stats':
					return baseUrl + 'no_stats_cuate.svg';
				default:
					return baseUrl + 'to_the_starts_cuate.svg'
			}
		}
	},
	mixins: [],
	components: {

	}
}
</script>

<style scoped>

</style>
