<template>
	<div class="layout-footer p-d-flex p-jc-center p-ai-center">
		<div class="footer-content">
			<span class="footer-text" style="margin-right: 10px">FreeCard</span>
			<img src="/assets/images/icons/logo-freecard.svg" alt="freecard" width="30"/>
			<span class="footer-text" style="margin-left: 10px">{{ date }}</span>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	export default {
		name: "AppFooter",
		computed: {
			date(){
				let d = new moment();
				return d.format('Y');
			}
		}
	}
</script>

<style scoped>

</style>